import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3f5316bb24860b0436c85523c4e54232@o4507516136259584.ingest.de.sentry.io/4507698440503376",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});